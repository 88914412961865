import React from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { Wrap } from 'components/composition/Wrap/Wrap';
import { Layout } from 'components/composition/Layout/Layout';
import { Box } from 'components/canvas/Box/Box';
import { Title } from 'components/content/Title/Title';
import { Lead } from 'components/content/Lead/Lead';
import { Spacing } from 'components/composition/Spacing/Spacing';

const Error = ({ statusCode }: { statusCode: number }) => (
  <>
    <Head>
      <title>Error {statusCode} | The Food Waste Atlas</title>
    </Head>
    <Wrap small>
      <Layout>
        <Layout.Centered>
          <Box>
            <Title color="brandSecondary">
              {statusCode || 'Ut oh'}
            </Title>
            <Lead large>
              {statusCode === 404
                ? 'The page you\'re looking for doesn\'t exist.'
                : 'An error occurred whilst loading the page.'}
            </Lead>
            <Spacing top="md">
              <Link href="/">
                <a>Back to Home</a>
              </Link>
            </Spacing>
          </Box>
        </Layout.Centered>
      </Layout>
    </Wrap>
  </>
);

Error.getInitialProps = ({ res, err }) => {
  if (res) {
    return { statusCode: res.statusCode };
  }

  return err ? err.statusCode : 404;
};

export default Error;
